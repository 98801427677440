import React, { useState } from 'react';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WWUpdateData } from './ww-last-update-data';

interface IProps {
  slug: string;
  element: string;
  update: string;
}

export const WWLastUpdate: React.FC<IProps> = ({ slug, element, update }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateData, setUpdateData] = useState(
    WWUpdateData.filter((updateCharacter) => {
      if (updateCharacter.slug === slug) return true;
    })
  );
  return (
    <>
      <div className={`content-header ${element}`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Update tracker
      </div>
      <div className="last-update">
        <div className="element review">
          <p className={`name ${element}`}>Last review update</p>
          <div className="info">
            <p>
              {slug === 'changli' ? (
                <>-</>
              ) : (
                <>
                  Patch{' '}
                  {updateData[0].review && (
                    <>
                      {updateData[0].review === 1.0
                        ? '1.0'
                        : updateData[0].review}
                    </>
                  )}
                </>
              )}
            </p>
          </div>
        </div>
        <div className="element build">
          <p className={`name ${element}`}>Last major build/calcs update</p>
          <div className="info">
            <p>
              Patch{' '}
              {updateData[0].build && (
                <>{updateData[0].build === 1.0 ? '1.0' : updateData[0].build}</>
              )}
            </p>
          </div>
        </div>
        <div className="element profile">
          <p className={`name ${element}`}>Last profile update*</p>
          <div className="info">
            <p>{update}</p>
          </div>
        </div>
      </div>
      <p className="profile-info">
        *Profile update means smaller edits to echoes, stats, synergies or teams
        that don't require new calculations.
      </p>
    </>
  );
};
