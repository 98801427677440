export const WWUpdateData = [
  {
    slug: 'aalto',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'baizhi',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'camellya',
    build: 1.4,
    review: 1.4
  },
  {
    slug: 'calcharo',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'changli',
    build: 1.3,
    review: 1.1
  },
  {
    slug: 'chixia',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'danjin',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'encore',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'jianxin',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'jinhsi',
    build: 1.3,
    review: 1.1
  },
  {
    slug: 'jiyan',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'lingyang',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'mortefi',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'rover-havoc',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'rover-spectro',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'sanhua',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'taoqi',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'the-shorekeeper',
    build: 1.3,
    review: 1.3
  },
  {
    slug: 'verina',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'xiangli-yao',
    build: 1.3,
    review: 1.2
  },
  {
    slug: 'yangyang',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'yinlin',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'youhu',
    build: 1.3,
    review: 1.3
  },
  {
    slug: 'yuanwu',
    build: 1.3,
    review: 1.0
  },
  {
    slug: 'zhezhi',
    build: 1.3,
    review: 1.2
  }
];
