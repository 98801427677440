import React from 'react';
import { WWStat } from '../common/components/ww-stat';

interface IProps {
  name: string;
}

export const WWEchoAlt: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'camellya' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'xiangli-yao' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'changli' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'jinhsi' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'calcharo' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'yuanwu' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="DEF%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="DEF%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="DEF%" />
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'yinlin' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'danjin' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
          </div>
        </>
      )}
      {name === 'rover-havoc' && (
        <>
          <h6>4-4-1-1-1:</h6>
          <div className="main-stats">
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT Rate" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Overlord">4 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="CRIT DMG" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
            <div className="box">
              <div className="stats-inside">
                <strong className="Common">1 cost</strong>
              </div>
              <div className="list-stats">
                <WWStat stat="ATK%" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
